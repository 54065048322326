import { eventChannel, END } from 'redux-saga';
import {
  takeEvery,
  all,
  put,
  take,
  fork,
  call,
  select,
  cancel as cancelFork,
} from 'redux-saga/effects';
import { replace } from '@loan_market/react-router-redux-multi';

import {
  LOAD_DOCUMENT,
  CREATE_DOCUMENT,
  CLEAR_WORKING_DOCUMENT,
  UPLOAD_DOCUMENT,
  DELETE_DOCUMENT,
  UPDATE_DOCUMENT,
  REQUEST_PAGE_DETAILS,
  GET_FILE_INVITE,
} from 'actions/documentActionTypes';
import documentActions from 'actions/documentActions';
import uiActions from 'actions/UIActions';

import {
  getSignedUrl,
  putPresigned,
  getCancelTokenSource,
  putDocument,
  delDocument,
  getFileInvite,
} from 'services/documentApi';
import { getPage } from 'services/pageApi';

import { getDocumentsForLoanApplication } from 'services/loanApplicationApi';
import { monitorAsyncRequest, CANCELED_MESSAGE } from 'lib/sagaHelpers.js';
import LocalStorageProxy from 'lib/localStorageProxy';
import { APPLY_BASE_PATH } from 'shared/constants/paths';
import {
  documentSubCategoryLookup,
  documentTypesPerSubCategoryKey,
} from 'shared/constants/myCRMTypes/documents';
import {
  STATUS_SUCCESS,
  STATUS_PENDING,
  STATUS_ERROR,
} from 'shared/constants/myCRMTypes/status';
import { PROFILE_SECTIONS } from 'constants/profileSections';
import { DOCUMENT_ERROR_TYPES } from 'constants/documents';
import { NO_ERROR } from 'constants/validators';

import locale from 'config/locale';
import { logger } from 'lib/coreLogger';

import * as applicationSelectors from 'selectors/applicationSelectors';
import * as documentSelectors from 'selectors/documentSelectors';
import * as contactSelectors from 'selectors/contactSelectors';
import * as UISelectors from 'selectors/UISelectors';
import { ASYNC_REQUEST_TYPE } from 'constants/options';

let pollingTasks = [];
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function* pollPendingDocumentForThumbnail(id) {
  try {
    while (true) {
      // this is a total hack.
      // we need an api to get a single document and not poll all documents
      const applicationId = yield select(applicationSelectors.getApplicationId);
      const documents = yield call(
        getDocumentsForLoanApplication,
        applicationId,
        locale.countryCode,
      );
      const doc = documents.find((d) => d.id === id);
      if (!doc) {
        break;
      }
      if (doc.status === STATUS_SUCCESS || doc.status === STATUS_ERROR) {
        // set working document to new document and get page details
        yield put(documentActions.setNewDocuments(documents));
        yield put(
          documentActions.setDocumentPages({ id: doc.id, value: doc.pages }),
        );
        if (doc.pages.length === 0) {
          break;
        }
        const firstPage = doc.pages[0];
        if (!firstPage.thumbnailUri) {
          yield put(
            documentActions.requestPageDetails({
              id: firstPage.id,
              documentId: doc.id,
            }),
          );
        }
        break;
      }
      yield delay(500);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchProgress(channel, id) {
  // eslint-disable-next-line sonarjs/prefer-while
  for (;;) {
    const data = yield take(channel);
    yield put(documentActions.setDocumentUploading(id)(data.progress));
    if (data.progress === 1) {
      break;
    }
  }
}

export function readFileIntoArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result);
    });
    reader.addEventListener('abort', resolve);
    reader.addEventListener('error', reject);
    reader.readAsArrayBuffer(file);
  });
}

function newEmptyDoc(params) {
  const { subCategory } = params;
  const doc = {
    ...params,
    status: STATUS_PENDING,
    pages: [],
  };
  if (subCategory) {
    doc.type = documentTypesPerSubCategoryKey(locale.countryCode)[
      subCategory
    ][0].id;
    doc.category = documentSubCategoryLookup(locale.countryCode)[
      subCategory
    ].category;
  }
  return doc;
}

export function createUploader() {
  const emit = {};
  const channel = eventChannel((emitter) => {
    emit.emitter = emitter;
    return () => {};
  });
  const source = getCancelTokenSource();
  const options = {
    cancelToken: source.token,
    onUploadProgress({ loaded, total }) {
      const progress = loaded / total;
      emit.emitter({ progress, time: Date.now() });
      if (progress === 1) {
        emit.emitter(END);
      }
    },
  };
  const canceler = () => {
    source.cancel(CANCELED_MESSAGE);
  };
  return { channel, canceler, options };
}

export function* createDocument({ payload }) {
  const {
    file,
    subCategory,
    applicationId,
    baseUrlToRedirectTo = `${APPLY_BASE_PATH}/${applicationId}`,
  } = payload;
  const binary = yield call(readFileIntoArrayBuffer, file);
  const newDocument = newEmptyDoc({
    id: 'upload',
    name: file.name,
    contentType: file.type,
    size: file.size,
    subCategory,
    binary,
  });
  yield put(documentActions.setWorkingDocument(newDocument));
  yield put(replace(`${baseUrlToRedirectTo}/documents/document/upload`));
}

export function* handleInvalidFileError(id) {
  logger('documentSagas').info({
    action: 'handleInvalidFileError',
    data: { id },
  });
  yield put(
    documentActions.setDocumentError('upload')({
      id,
      blocking: true,
      type: DOCUMENT_ERROR_TYPES.INVALID_FILE,
      text: 'There was a problem uploading your file.',
      description:
        'If it’s a picture or scanned document, try saving it as a PDF document.',
    }),
  );
}
export function* uploadDocument({ payload }) {
  const { name, contentType, size, subCategory, type, binary, id } = payload;
  if (size === 0) {
    yield call(handleInvalidFileError, id);
    return;
  }

  yield put(
    documentActions.setDocumentError('upload')({
      id,
      text: NO_ERROR,
    }),
  );
  const applicationId = yield select(applicationSelectors.getApplicationId);
  const contact = yield select(contactSelectors.primaryContact);

  try {
    const document = yield call(getSignedUrl, {
      loanApplicationId: applicationId,
      name,
      contentType,
      size,
      subCategory,
      type,
      contactId: contact.id,
      countryCode: locale.countryCode,
    });

    // eslint-disable-next-line no-unused-vars
    const { channel, canceler, options } = createUploader();
    options.headers = { 'content-type': contentType };
    const uploadPromise = putPresigned(document.url, binary, options);
    // TODO: show progress bar on save instead of spinner
    // yield put(documentActions.setAxiosRequestCanceler(id)(canceler));
    // const progressWatcher = yield fork(watchProgress, channel, id);
    yield uploadPromise;
    // yield cancelFork(progressWatcher);
    // yield put(documentActions.setDocumentUploading(id)(1));
    yield put(
      documentActions.setNewDocument({ ...document, status: STATUS_SUCCESS }),
    );
    yield put(documentActions.clearWorkingDocument(id));
  } catch (error) {
    logger('documentSagas').error({
      action: 'uploadDocument',
      errorDescription: 'Failed to upload the document',
      errorStack: error,
    });
    const { status } = error.response || {};
    if (status === 400) {
      yield call(handleInvalidFileError, id);
    } else {
      throw error;
    }
  }
}

export function* loadDocument({ payload }) {
  const id = parseInt(payload, 10) || payload;
  if (id === 'new') {
    return;
  }
  const doc = yield select((state) => documentSelectors.entity(state, id));
  if (doc.status === STATUS_PENDING) {
    const task = yield fork(pollPendingDocumentForThumbnail, id);
    pollingTasks.push(task);
  }
}

export function* clearWorkingDocument({ payload }) {
  const id = parseInt(payload, 10) || payload;
  if (id === 'new') {
    return;
  }
  // for now just cancel all, but in future do this based on the id of the document
  // eslint-disable-next-line no-unused-vars
  for (const element of pollingTasks) {
    yield cancelFork(element);
  }
  pollingTasks = [];
}

export function* updateDocument({ payload }) {
  const applicationId = yield select(applicationSelectors.getApplicationId);
  const document = yield call(putDocument, payload.id, {
    ...payload,
    applicationId,
    countryCode: locale.countryCode,
  });
  yield put(documentActions.setDocument(document));
}

export function* fetchPageDetails({ payload: { id, documentId } }) {
  const details = yield call(getPage, id);
  yield put(documentActions.setPageDetails({ documentId, ...details }));
  yield put(documentActions.loadDocument(documentId));
}

export function* deleteDocument({ payload }) {
  yield call(delDocument, payload);
  yield put(documentActions.removeDocument(payload));
}

export function* fetchDocuments() {
  const applicationId = yield select(applicationSelectors.getApplicationId);
  const documents = yield call(
    getDocumentsForLoanApplication,
    applicationId,
    locale.countryCode,
  );

  yield put(documentActions.setNewDocuments(documents));
}

export function* getClientFileInvite({ payload }) {
  try {
    const fileInvite = yield call(getFileInvite, payload.clientId);
    const sections = yield select(UISelectors.partialProfileSections);
    if (
      fileInvite.inviteUrl &&
      sections &&
      sections.length &&
      !sections.includes(PROFILE_SECTIONS.DOCUMENTS)
    ) {
      const updatedSections = [...sections, PROFILE_SECTIONS.DOCUMENTS];
      LocalStorageProxy.partialProfileSections = JSON.stringify(
        updatedSections,
      );
      yield put(uiActions.setPartialProfileSections([...updatedSections]));
    }
    yield put(documentActions.setFileInvite(fileInvite));
  } catch (error) {
    console.error(error);
  }
}

function* watchLoadDocument() {
  yield takeEvery(LOAD_DOCUMENT, loadDocument);
}

function* watchCreateDocument() {
  yield takeEvery(CREATE_DOCUMENT, createDocument);
}

function* watchClearWorkingDocument() {
  yield takeEvery(CLEAR_WORKING_DOCUMENT, clearWorkingDocument);
}

function* watchUploadDocument() {
  yield monitorAsyncRequest(
    takeEvery,
    UPLOAD_DOCUMENT,
    uploadDocument,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchRequestPageDetails() {
  /* TODO: implement monitorAsyncRequest with configurable status message? */
  yield takeEvery(REQUEST_PAGE_DETAILS, fetchPageDetails);
}

function* watchUpdateDocument() {
  yield monitorAsyncRequest(
    takeEvery,
    UPDATE_DOCUMENT,
    updateDocument,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchDeleteDocument() {
  yield monitorAsyncRequest(
    takeEvery,
    DELETE_DOCUMENT,
    deleteDocument,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchGetFileInvite() {
  yield takeEvery(GET_FILE_INVITE, getClientFileInvite);
}

export default function* documentSagas() {
  yield all([
    watchLoadDocument(),
    watchCreateDocument(),
    watchUploadDocument(),
    watchRequestPageDetails(),
    watchUpdateDocument(),
    watchDeleteDocument(),
    watchClearWorkingDocument(),
    watchGetFileInvite(),
  ]);
}
