import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Questions from 'lib/Questions';
import { REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO } from 'constants/validators';
import { FREQUENCY_OPTIONS } from 'constants/options';
import { MONTHLY } from 'shared/constants/options';
import { UNCATEGORISED_EXPENSE_NAME } from 'shared/constants/myCRMTypes/expenses';

import CurrencyInputWithSelection from 'components/CurrencyInputWithSelection/CurrencyInputWithSelection';
import Form from 'components/Form/Form';
import Selection from 'components/Selection/Selection';
import Question from 'components/Question/Question';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { renderExpenseHeading } from 'components/Popups/ExpensePopup';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

const showDescription = (props) =>
  !props.uncategorised && (props.isOther || !!props.description);

const propsTransformForQuestion = (props) => ({
  ...props.working,
  isOther: props.typeInfo.isOther,
  uncategorised: props.uncategorised,
  clientIdOwnershipOptions: props.clientIdOwnershipOptions,
});

export const questionSet = (props) => {
  const questions = new Questions();
  if (showDescription(props)) {
    questions.addBranch(
      ['description', REQUIRED],
      ['value', REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO],
      ['clientIds', REQUIRED],
    );
  } else {
    questions.addBranch(
      ['value', REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO],
      ['clientIds', REQUIRED],
    );
  }
  return questions.arrayOfQuestions();
};

export class DisconnectedExpenseForm extends Component {
  static propTypes = {
    working: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setFrequency: PropTypes.func.isRequired,
    setExpenseClientIds: PropTypes.func.isRequired,
    clientIdOwnershipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLocked: PropTypes.bool,
    formCompleted: PropTypes.bool,
    uncategorised: PropTypes.bool,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  componentDidMount() {
    const {
      working,
      setName,
      uncategorised,
      setExpenseClientIds,
      clientIdOwnershipOptions,
    } = this.props;
    if (!working.id) {
      if (uncategorised) {
        setName(UNCATEGORISED_EXPENSE_NAME);
      }
      if (clientIdOwnershipOptions.length === 1) {
        setExpenseClientIds(clientIdOwnershipOptions[0].value);
      }
      if (clientIdOwnershipOptions.length === 3) {
        setExpenseClientIds(clientIdOwnershipOptions[2].value);
      }
    }
  }

  onSubmit = () => {
    this.props.save();
  };

  handleRemove = () => {
    const {
      remove,
      working: { id },
    } = this.props;

    remove(id);
  };

  render() {
    const {
      typeInfo,
      isLocked,
      questions,
      action,
      setName,
      setFrequency,
      uncategorised,
      formCompleted,
      setCurrentQuestionTo,
      working,
      clientIdOwnershipOptions,
      setExpenseClientIds,
    } = this.props;
    const valueLabel = showDescription({
      ...working,
      isOther: typeInfo.isOther,
    })
      ? 'Value'
      : undefined;

    return (
      <Form
        id='expenseForm'
        formCompleted={formCompleted}
        onSubmit={this.onSubmit}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        {!typeInfo.isOther &&
          renderExpenseHeading(typeInfo.icon, typeInfo.name)}
        <fieldset disabled={isLocked}>
          <Question
            {...questions.description}
            className='fullWidthInput'
            label='Description'
          >
            <Input
              type='text'
              maxLength={50}
              value={working.description}
              action={setName}
            />
          </Question>
          <Question
            {...questions.value}
            className='fullWidthInput'
            label={valueLabel}
            hint='How much do you want to budget for while paying off your loan?'
          >
            <CurrencyInputWithSelection
              maxLength={9}
              value={working.value}
              action={action}
              selectionDefault={MONTHLY}
              selectionValue={working.frequency}
              selectionAction={setFrequency}
              selectionItems={FREQUENCY_OPTIONS}
            />
          </Question>
          <Question
            {...questions.clientIds}
            className='fullWidthInput'
            label='Ownership'
          >
            <Selection
              action={setExpenseClientIds}
              value={working.clientIds && working.clientIds.join()}
              items={clientIdOwnershipOptions}
            />
          </Question>
          <Button
            disabled={!formCompleted}
            id='PopupButton'
            className='brandColor__button margin-0'
            onClick={this.onSubmit}
          >
            {!working.id && typeInfo.isOther && !uncategorised
              ? 'Add'
              : 'Confirm'}
          </Button>
          {working.id && working.id > 0 && (
            <Button
              className='brandColor__reverseButton'
              onClick={this.handleRemove}
            >
              Remove
            </Button>
          )}
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  }
}

export default manageQuestions(
  DisconnectedExpenseForm,
  propsTransformForQuestion,
);
