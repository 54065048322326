import { logEvent, EVENTS } from 'lib/amplitude';
import { signOut } from 'lib/okta';
import LocalStorageProxy from 'lib/localStorageProxy';

export const logoutUtil = async (path, oktaAuth) => {
  logEvent(EVENTS.LOG_OUT);
  await signOut(oktaAuth);
  LocalStorageProxy.clearAll();
  sessionStorage.clear();
  LocalStorageProxy.logoutRedirectUri = path;
};

export const getStoragePath = (origin, pathname) => `${origin}${pathname}#`;

export const setLoginCurrentPath = () => {
  const { origin, pathname } = window.location;
  const redirectPath = getStoragePath(origin, pathname);
  LocalStorageProxy.loginCurrentPath = redirectPath;
};

export const validateLoginCurrentPath = () => {
  const { origin, pathname } = window.location;
  const currentPath = getStoragePath(origin, pathname);
  if (
    LocalStorageProxy.loginCurrentPath &&
    LocalStorageProxy.loginCurrentPath !== currentPath
  ) {
    window.location.replace(LocalStorageProxy.loginCurrentPath);
  }
};
