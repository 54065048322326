import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import Dropzone from 'react-dropzone';
import classNames from 'classnames/bind';

import { formatSizeFromBytes } from 'lib/intlFormatters';
import locale from 'config/locale';
import { logEvent, EVENTS } from 'lib/amplitude';

import documentActions from 'actions/documentActions';
import UIActions from 'actions/UIActions';

import * as applicationSelectors from 'selectors/applicationSelectors';
import * as documentSelectors from 'selectors/documentSelectors';
import * as advisorSelectors from 'selectors/advisorSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';
import Tooltip from 'components/Tooltip/Tooltip';
import DocumentGroupRecord from 'components/DocumentGroupRecord/DocumentGroupRecord';

import { documentSubCategoriesPerCategoryKey } from 'shared/constants/myCRMTypes/documents';
import { SUB_CATEGORY_BANK_STATEMENTS } from 'shared/constants/myCRMTypes/documentsAU';
import { MIME_TYPES, MIME_EXTENSIONS } from 'shared/constants/myCRMTypes/mimes';
import { MAX_FILE_SIZE } from 'shared/constants/myCRMTypes/general';

import {
  documentCategories,
  fileInviteCategories,
  DOCUMENT_CATEGORY_FILE_INVITE,
} from 'constants/documents';
import { featureFlags } from 'lib/rollout';

import styles from './DocumentsApply.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import NotifBannerStyles from '../NotificationBanner/NotificationBanner.css';
import * as expenseSelectors from '../../selectors/expenseSelectors';

export const messages = defineMessages({
  title: {
    id: 'DocumentsApply.title',
    defaultMessage: 'Documents',
  },
  titleDescription: {
    id: 'DocumentsApply.titleDescription',
    defaultMessage:
      '{fileCount} uploaded {fileCount, plural, one {document} other {documents}}',
  },
  titleDescriptionEmpty: {
    id: 'DocumentsApply.titleDescriptionEmpty',
    defaultMessage: 'Upload supporting documents',
  },
  headerDescription: {
    id: 'DocumentsApply.headerDescription',
    defaultMessage: 'Let’s get your supporting documents ready',
  },
  dragAndDrop: {
    id: 'DocumentsApply.dragAndDrop',
    defaultMessage: 'Drag and Drop an attachment here or',
  },
  dragAndDropActive: {
    id: 'DocumentsApply.dragAndDropActive',
    defaultMessage: 'Drop attachment here',
  },
  manuallySelect: {
    id: 'DocumentsApply.manuallySelect',
    defaultMessage: 'Manually select the file',
  },
  done: {
    id: 'DocumentsApply.done',
    defaultMessage: 'Save and close',
  },
  errorFileSize: {
    id: 'DocumentsApply.errorFileSize',
    defaultMessage:
      'Your file is too large, please make sure that it is less than {size}.',
  },
  errorFileType: {
    id: 'DocumentsApply.errorFileType',
    defaultMessage: 'Supported file types: {fileTypes}',
  },
  singleFile: {
    id: 'DocumentsApply.singleFile',
    defaultMessage: 'Only one document is allowed each time',
  },
  tryAgain: {
    id: 'DocumentsApply.tryAgain',
    defaultMessage: 'Please select another file and try again.',
  },
});

const ACCEPTED_FILES = MIME_TYPES.join();
const ACCEPTED_EXTENSIONS = MIME_EXTENSIONS.sort().join(', ');

export const furtherDecoration = (props) => {
  const {
    totalDocuments: fileCount,
    intl: { formatMessage },
  } = props;
  const postfix = fileCount === 0 ? 'Empty' : '';

  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      fileCount,
    }),
    headerDescription: formatMessage(messages.headerDescription),
  };
};

export class DocumentsApply extends Component {
  static displayName = 'DocumentsApply';
  static propTypes = {
    intl: intlShape.isRequired,
    applicationId: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    push: PropTypes.func.isRequired,
    createDocument: PropTypes.func.isRequired,
    getFileInvite: PropTypes.func.isRequired,
    documentsGroupedBySubCategory: PropTypes.object,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    startAnimationSequence: PropTypes.func.isRequired,
    isCustomerCare: PropTypes.bool,
    hasBankConnectSubscription: PropTypes.bool,
    primaryApplicantId: PropTypes.number,
    urlPath: PropTypes.string.isRequired,
    fileInvite: PropTypes.object,
    slug: PropTypes.string,
    openAccordion: PropTypes.string,
    reselectFile: PropTypes.bool,
    setReselectFile: PropTypes.func,
    documentTypes: PropTypes.arrayOf(PropTypes.object),
  };

  onFocus = () => {
    const {
      fileInvite,
      getFileInvite,
      primaryApplicantId,
      slug,
      openAccordion,
    } = this.props;
    const isFileInvite =
      featureFlags.fileInviteDocs.isEnabled() && fileInvite.inviteUrl;
    if (isFileInvite && openAccordion === slug) {
      getFileInvite({ clientId: primaryApplicantId });
    }
  };

  retryFileUpload = () => {
    this.openFileDialog(this.subCategory)();
    this.props.setReselectFile(false);
  };

  componentDidMount() {
    window.addEventListener('focus', this.onFocus);
  }

  componentWilUnmount() {
    window.removeEventListener('focus', this.onFocus);
  }

  componentDidUpdate(prevProps) {
    const isReselectFileChanged =
      prevProps.reselectFile !== this.props.reselectFile;
    if (isReselectFileChanged && this.props.reselectFile) {
      this.retryFileUpload();
    }
  }

  onFileDrop = (files, rejected) => {
    const { createDocument, applicationId, urlPath } = this.props;

    if (files.length && rejected.length === 0) {
      createDocument({
        file: files[0],
        subCategory: this.subCategory,
        applicationId,
        baseUrlToRedirectTo: urlPath,
      });
      this.subCategory = null;
    }
  };

  getDropMessage({ isDragActive, isDragReject, draggedFiles }) {
    const {
      intl,
      intl: { formatMessage },
    } = this.props;
    let message;

    if (isDragActive) {
      if (draggedFiles.length > 1) {
        message = formatMessage(messages.singleFile);
      } else if (isDragReject && draggedFiles.length === 1) {
        if (draggedFiles[0].size > MAX_FILE_SIZE) {
          message = formatMessage(messages.errorFileSize, {
            size: formatSizeFromBytes(intl)(MAX_FILE_SIZE),
          });
        } else {
          message = formatMessage(messages.errorFileType, {
            fileTypes: ACCEPTED_EXTENSIONS,
          });
        }
      }
    } else {
      message = formatMessage(messages.manuallySelect);
    }

    return message;
  }

  onAddDocument = (type) => {
    logEvent(EVENTS.ADD_DOCUMENTS, { type });
  };

  openFileDialog = (subCategory) => () => {
    this.subCategory = subCategory;
    this.dropzoneRef.open();
    this.onAddDocument('Fact find');
  };

  connectBankStatements = () => {
    const { startAnimationSequence } = this.props;
    logEvent(EVENTS.START_BANK_CONNECT);
    startAnimationSequence([
      '/grab-bank-statements',
      '/bank-connect-or-skip?fullReport=false',
    ]);
  };

  onOpenFileInvite = (fileInvite) => () => {
    window.open(fileInvite.inviteUrl, '_blank');
    this.onAddDocument('File invite');
  };

  renderFileInvite = () => {
    const {
      accordionProps: { isLocked },
      fileInvite,
      documentTypes,
    } = this.props;
    const category = DOCUMENT_CATEGORY_FILE_INVITE;

    return (
      <div key={category.key}>
        <div>
          <span className={styles.title}>{category.title}</span>
        </div>
        <div className={styles.description}>
          Use our secure portal <i>File Invite</i> to upload your documents.
        </div>
        <DocumentGroupRecord
          key={category.key}
          subCategory={category.key}
          addFile={this.onOpenFileInvite(fileInvite)}
          isLocked={isLocked}
          pendingCount={fileInvite.pendingCount}
          documentTypes={documentTypes}
        />
      </div>
    );
  };

  renderUploadItemSection = (category) => {
    const {
      documentsGroupedBySubCategory,
      accordionProps: { isLocked },
      hasBankConnectSubscription,
      isCustomerCare,
      documentTypes,
    } = this.props;
    const subCategories = documentSubCategoriesPerCategoryKey(locale)[
      category.key
    ];

    return (
      <div key={category.key}>
        <div>
          <span className={styles.title}>{category.title}</span>
          <Tooltip
            id={`${category.key}_tooltip`}
            tooltip={category.tooltip}
            dataPlace='bottom'
            dataOffset='bottom'
          />
        </div>
        <div className={styles.description}>{category.description}</div>
        {subCategories.map((subCategory) => {
          const showBankConnectLink =
            subCategory.key === SUB_CATEGORY_BANK_STATEMENTS.key &&
            (hasBankConnectSubscription || isCustomerCare);
          return (
            <DocumentGroupRecord
              key={subCategory.key}
              subCategory={subCategory.name}
              showBankConnectLink={showBankConnectLink}
              addFile={this.openFileDialog(subCategory.key)}
              connectBankStatements={this.connectBankStatements}
              documents={documentsGroupedBySubCategory[subCategory.key]}
              isLocked={isLocked}
              documentTypes={documentTypes}
            />
          );
        })}
      </div>
    );
  };

  renderDropZoneContent = ({ isDragActive, isDragReject, draggedFiles }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const postFix = isDragActive ? 'Active' : '';

    return (
      <div className={styles.content}>
        <i
          className={classNames(styles.uploadIcon, 'sl-custom-cloud-upload')}
        />
        <div className={styles.dragAndDrop}>
          {formatMessage(messages[`dragAndDrop${postFix}`])}
        </div>
        <div>
          {this.getDropMessage({ isDragActive, isDragReject, draggedFiles })}
        </div>
      </div>
    );
  };

  render() {
    const {
      accordionProps: { isLocked },
      fileInvite,
      hasBankConnectSubscription,
      isDataCollectionBankStatementsSuccessful,
      isDataCollectionBankStatementsSkipped,
      displayDataCollectionBanners,
    } = this.props;
    const isFileInvite =
      featureFlags.fileInviteDocs.isEnabled() &&
      fileInvite.enabled &&
      fileInvite.inviteUrl;
    const itemSections = isFileInvite
      ? fileInviteCategories
      : documentCategories;

    return (
      <div>
        {displayDataCollectionBanners &&
          isDataCollectionBankStatementsSuccessful && (
            <NotificationBanner
              message='Bank statements connected! Based on the bank statement information you provided, we have automatically uploaded a few documents.'
              align='center'
              className={NotifBannerStyles.success}
            />
          )}
        {displayDataCollectionBanners &&
          isDataCollectionBankStatementsSkipped && (
            <NotificationBanner
              message='Bank statements required! As you opted to provide statements manually, please upload statements and transaction listings for all your banking and credit card accounts for the last 180 days from today. These must be offical bank documents in PDF format.'
              align='center'
              className={NotifBannerStyles.infoBlue}
            />
          )}
        {!isLocked && (
          <Dropzone
            ref={(node) => {
              this.dropzoneRef = node;
            }}
            accept={ACCEPTED_FILES}
            onDrop={this.onFileDrop}
            multiple={false}
            maxSize={MAX_FILE_SIZE}
            className={classNames(styles.uploadArea, {
              [styles.hidden]: !!isFileInvite,
            })}
            rejectClassName={styles.reject}
            acceptClassName={styles.accept}
          >
            {this.renderDropZoneContent}
          </Dropzone>
        )}
        {isFileInvite
          ? this.renderFileInvite()
          : itemSections(hasBankConnectSubscription).map(
              this.renderUploadItemSection,
            )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  applicationId: applicationSelectors.workingApplication(state).id,
  documentsGroupedBySubCategory: documentSelectors.documentsGroupedBySubCategory(
    state,
  ),
  hasMainCategories: documentSelectors.getHasMainCategories(state),
  totalDocuments: documentSelectors.getDocumentCount(state),
  isCustomerCare: applicationSelectors.isBrokerCustomerCare(state),
  hasBankConnectSubscription: advisorSelectors.hasBankConnectSubscription(
    state,
  ),
  primaryApplicantId: clientSelectors.getPrimaryApplicantId(state),
  fileInvite: documentSelectors.fileInvite(state),
  documentTypes: documentSelectors.documentTypes(state),
  reselectFile: state.document.reselectFile,

  isDataCollectionBankStatementsSuccessful: dataCollectionSelectors.isBankStatementsSuccessful(
    state,
  ),
  isDataCollectionBankStatementsSkipped: dataCollectionSelectors.isBankStatementsSkipped(
    state,
  ),
  displayDataCollectionBanners: expenseSelectors.displayDataCollectionBanners(
    state,
  ),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      push,
      createDocument: documentActions.createDocument,
      getFileInvite: documentActions.getFileInvite,
      startAnimationSequence: UIActions.startAnimationSequence,
      setReselectFile: documentActions.setReselectFile,
    },
    dispatch,
  );
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => {
    ownProps.onSubmit();
  },
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  )(
    applySection({
      iconName: 'sl-custom-files-1',
      furtherDecoration,
    })(DocumentsApply),
  ),
);
