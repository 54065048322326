import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as privacyPolicySelectors from 'selectors/privacyPolicySelectors';
import styles from './HelpFooter.css';
import locale from 'config/locale';

const HelpFooter = ({ privacyPolicyLink, isOwnBrand }) => {
  return (
    <footer className={styles.root}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.helpTitle}>Need help?</div>
          <div>
            For more information on this service or if you have questions,
            please contact us on{' '}
            <a href='tel:+61 1800 275 564' className={styles.phoneLink}>
              +61 1800 275 564
            </a>
          </div>
          <div className={styles.flexFooter}>
            <span className={styles.complianceItem}>
              <a
                href={privacyPolicyLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy policy
              </a>
            </span>
            <span className={styles.complianceItem}>
              <a
                href={`${
                  isOwnBrand
                    ? 'https://loanmarketgroup.com'
                    : locale.data.website
                }/terms-and-conditions`}
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms and Conditions
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

HelpFooter.propTypes = {
  privacyPolicyLink: PropTypes.string,
  isOwnBrand: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  privacyPolicyLink: privacyPolicySelectors.selectPrivacyPolicyLink(state),
  isOwnBrand: state.advisorOrg.isOwnBrand,
});

export default connect(mapStateToProps)(injectIntl(HelpFooter));
