import { ContextPageType } from 'constants/contextTypes';

export const getContextPageType = (
  isSmartDataEnabled,
  hasOldData,
  hasAlreadySeenContext,
) => {
  if (isSmartDataEnabled) {
    if (hasOldData) {
      return ContextPageType.SMART_DATA_OLD_DATA;
    }
    if (!hasAlreadySeenContext) {
      return ContextPageType.SMART_DATA_NEW_CLIENT;
    }
  }
  if (hasOldData) {
    return ContextPageType.NO_SMART_DATA_OLD_DATA;
  }
  if (!hasAlreadySeenContext) {
    return ContextPageType.NO_SMART_DATA_NEW_CLIENT;
  }
};
