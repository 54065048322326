import axios from 'axios';
import { DIRECT_DOCUMENTS_API_PATH } from 'shared/constants/apiPaths';
import locale from 'config/locale';

import { returnJSON } from './baseApi';
import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import {
  axiosWithAuth as fileInviteAxiosWithAuth,
  returnJSON as fileInviteReturnJSON,
} from './fileInviteBaseApi';
import {
  documentBuilderForMyCRM,
  newDocumentBuilderForMyCRM,
} from '../../shared/lib/builders/mycrm/document';
import { postDocumentToLoanApplicationDirect } from './loanApplicationApi';
import {
  documentBuilderForMilli,
  documentCategoriesMapper,
} from '../../shared/lib/builders/milli/document';
import {
  getDocumentTypesAU as fetchDocumentTypesAU,
  getDocumentTypesNZ as fetchDocumentTypesNZ,
} from './optionsApi';

// Begin port of serverless documentApi
//
export const postCreateDocumentDirect = (data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_DOCUMENTS_API_PATH}/create-from-base64`, data, {
      maxContentLength: 20000000,
    })
    .then(directReturnJSON);
};

const putDocumentDirect = (documentId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_DOCUMENTS_API_PATH}/${documentId}`, data)
    .then(directReturnJSON);
};

const deleteDocumentDirect = (documentId) => {
  return directAxiosWithAuth
    .delete(`${DIRECT_DOCUMENTS_API_PATH}`, {
      data: {
        DocumentId: documentId,
      },
    })
    .then(directReturnJSON);
};
//
// End port of serverless documentApi

// Begin port of serverless fileInviteApi
//
const getClientFileInviteDirect = (clientId) => {
  return fileInviteAxiosWithAuth
    .get(`/clients/${clientId}/invites/latest`)
    .then(fileInviteReturnJSON);
};
//
// End port of serverless fileInviteApi

export function getCancelTokenSource() {
  return axios.CancelToken.source();
}

export function putPresigned(presigned, binary, options) {
  return axios.put(presigned, binary, options).then(returnJSON);
}

// port of serverless handler: documents/documents.getSignedUrl
function safeFileName(name) {
  const now = new Date();
  const isotime = now.toISOString();
  const lastDot = name.lastIndexOf('.');
  const ext = lastDot > 0 ? name.slice(Math.max(0, lastDot)) : '';
  const basename = lastDot > 0 ? name.slice(0, Math.max(0, lastDot)) : name;
  return `${basename}_${isotime}${ext}`;
}

export function getSignedUrl(params) {
  params = {
    ...params,
    key: safeFileName(params.name),
  };

  return postDocumentToLoanApplicationDirect(
    params.loanApplicationId,
    newDocumentBuilderForMyCRM(params),
  ).then((data) => {
    return {
      url: data.signed_url,
      ...documentBuilderForMilli({
        ...data,
        countryCode: params.countryCode,
      }),
    };
  });
}

// port of serverless handler: documents/documents.put
export function putDocument(documentId, document) {
  return putDocumentDirect(documentId, documentBuilderForMyCRM(document)).then(
    (data) => {
      return documentBuilderForMilli({
        ...data[0],
        countryCode: document.countryCode,
      });
    },
  );
}

// port of serverless handler: documents/documents.del
export function delDocument(documentId) {
  return deleteDocumentDirect(documentId);
}

export function getFileInvite(clientId) {
  return getClientFileInviteDirect(clientId);
}

// port of serverless handler: documents/documents.getDocumentTypes
export function getDocumentTypes() {
  const countryCode = locale.countryCode;
  const fetchDocumentTypes =
    countryCode === 'NZ' ? fetchDocumentTypesNZ : fetchDocumentTypesAU;

  return fetchDocumentTypes().then((data) => {
    return documentCategoriesMapper(data, countryCode);
  });
}
