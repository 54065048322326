import { ADDRESS_TYPE_CURRENT } from 'shared/constants/myCRMTypes/address';
import { unformatDate } from 'shared/lib/utils';
import { historyBuilderForMilli } from './history';

export function addressBuilderForMilli(address) {
  const startDate = address.start_date ? unformatDate(address.start_date) : {};
  const typeId = address.address_type_id;
  const endDate =
    typeId !== ADDRESS_TYPE_CURRENT.id ? unformatDate(address.end_date) : {};
  const clientIds =
    address.client_ids && address.client_ids.length
      ? address.client_ids.sort()
      : address.entity_ids;

  const builtAddress = {
    formattedAddress: address.formatted_address,
  };
  if (address.completeDetails) {
    builtAddress.countryCode =
      address.completeDetails.countryCodeInTwoLetter ||
      address.completeDetails.CountryCode;
    builtAddress.state = address.completeDetails.stateShort_Name;
    builtAddress.streetNumber = address.completeDetails.street_number;
    builtAddress.streetName = address.completeDetails.route;
    builtAddress.suburb = address.completeDetails.locality;
  }

  return {
    id: address.id,
    clientIds,
    address: builtAddress,
    typeId,
    startDate,
    endDate,
    ownershipTypeId: address.ownership_type_id,
    ...historyBuilderForMilli(address),
  };
}
