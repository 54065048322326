import _ from 'lodash';
import {
  LOAN_FEATURES_MAP,
  ASSET_TYPE_MAP,
  LOAN_TYPE_FEATURES_MAP,
  EXPENSE_MAP,
  PROSPECTIVE_PROPERTY_FUNDING_MAP,
  OWNED_PROPERTY_FUNDING_MAP,
} from 'shared/constants/myCrmMaps';
import {
  postClientDirect,
  patchClientDirect,
  postLoanApplicationForClientDirect,
  postRealEstateForClientDirect,
  postExpenseForClientDirect,
} from '../services/clientApi';
import {
  postApplicantDirect,
  postPropertyForApplicationDirect,
} from '../services/loanApplicationApi';
import {
  postFundingsForPropertyDirect,
  postIncomeForPropertyDirect,
} from '../services/propertyApi';
import {
  postRealEstateIncomeForAssetDirect,
  postRealEstateLiabilityForAssetDirect,
} from '../services/assetApi';
import {
  postClientToFamilyDirect,
  postAddressForContactDirect,
  postIncomeForContactDirect,
} from '../services/contactApi';
import {
  PROSPECTIVE_PROPERTY_ID,
  DEFAULT_EXISTING_PROPERTY_ID,
} from 'shared/constants/defaults';
import {
  LM_ONLINE_MEMBER_BYOB,
  LM_ONLINE_MEMBER,
  WEBSITE_LOAN_MARKET,
  LM_ONLINE_LOAN_APP_SOURCE_ID,
  LM_BROKER_WEBISTE_MEMBER,
  SELF_GENERATED,
  RAYWHITE_CALCULATOR,
  REFERRER,
} from 'shared/constants/myCRMTypes/general';
import { FUNDING_TYPE_REQUIRED } from 'shared/constants/myCRMTypes/funding';
import {
  BUYER_SCENARIO_MOVE,
  BUYER_SCENARIO_FHB,
  BUYER_SCENARIO_INVEST,
  REFINANCE_SCENARIO_DEFAULT,
} from 'shared/constants/loanScenarios';
import {
  TRANSACTION_PURCHASING,
  PRIMARY_PURPOSE_OWNER_OCCUPIED,
  PRIMARY_PURPOSE_INVESTMENT,
  PROPERTY_STATUSES,
} from 'shared/constants/myCRMTypes/property';
import {
  INCOME_RENTAL,
  INCOME_RENTAL_CATEGORY,
} from 'shared/constants/myCRMTypes/incomes';
import { ROLE_ADULT, ROLE_CHILD } from 'shared/constants/myCRMTypes/clients';
import {
  LIABILITY_TERM_LOAN,
  LIABILITY_OTHER_CATEGORY,
} from 'shared/constants/myCRMTypes/liabilities';
import {
  ADDRESS_TYPE_CURRENT,
  ADDRESS_OWNERSHIP_TYPE_OWN_HOME,
  ADDRESS_OWNERSHIP_TYPE_OWN_HOME_MORTGAGE,
} from 'shared/constants/myCRMTypes/address';
import { OWNER_OCCUPIED_RESIDENCE } from 'shared/constants/options';

import { arrayOfTruthyKeys, parseLocality, intlMobile } from 'shared/lib/utils';
import { getLenderName } from 'shared/lib/lenderHelper';
import { notifyOrLog } from 'shared/lib/errorHandler';
import { MILLI_TO_MYCRM_FREQ_ID } from 'shared/lib/frequencyHelper';
import { buildPurposeFromScenario } from 'shared/lib/builders/milli/purpose';

export function buildClientData(scenario) {
  const {
    name,
    displayName,
    lastName,
    postcode,
    email,
    utm,
    mobile,
  } = scenario;
  return {
    name: {
      display: displayName,
      first: displayName,
      last: lastName,
      middle: '',
    },
    email,
    mobile: intlMobile(mobile),
    utm: {
      source: utm && utm.utmSource,
      medium: utm && utm.utmMedium,
      term: utm && utm.utmTerm,
      content: utm && utm.utmContent,
      campaign: utm && utm.utmCampaign,
    },
    is_first_home_buyers: name === BUYER_SCENARIO_FHB,
    allocation_postcode: postcode /* this is for corporate adivisers for allocation purposes */,
  };
}

export function buildRayWhiteData({ agents, office }) {
  return {
    agents,
    office,
    source_id: RAYWHITE_CALCULATOR,
    source_category_id: REFERRER,
  };
}

export const signup = (scenario) => {
  let params;
  try {
    params = buildClientData(scenario);
    params.is_lead = true;
    params.role_id = ROLE_ADULT;
    params.active = true;
    params.allocated_broker_family_id =
      scenario.allocatedBrokerFamilyId ||
      process.env.MYCRM_ALLOCATED_BROKER_ID; /* to avoid auto allocation */
    params.is_primary = true;
    params.create_new_lead = false;
    if (scenario.allocatedBrokerFamilyId) {
      params.source_id = scenario.isOwnBrand
        ? LM_ONLINE_MEMBER_BYOB
        : LM_BROKER_WEBISTE_MEMBER;
      params.source_category_id = SELF_GENERATED;
    } else if (scenario.isRayWhiteCalculator) {
      params = { ...params, ...buildRayWhiteData(scenario) };
    } else {
      params.source_id = LM_ONLINE_MEMBER;
      params.source_category_id = WEBSITE_LOAN_MARKET;
    }
  } catch (error) {
    return Promise.reject(error);
  }
  return postClientDirect(params);
};

export const setClientActivated = (clientId, { agents, office } = {}) => {
  const params = {
    isToActivate: true,
  };

  if (agents) {
    params.agents = agents;
  }
  if (office) {
    params.office = office;
  }

  return patchClientDirect(clientId, params).then((client) => {
    console.info(`Activated client ID: ${clientId}`);
    return client;
  });
};

function trueOrUndefined(value) {
  return value ? true : undefined;
}

function buildMetadata(scenario) {
  const { properties } = scenario;
  const existingProperty = properties[DEFAULT_EXISTING_PROPERTY_ID];
  const prospectiveProperty = properties[PROSPECTIVE_PROPERTY_ID];

  return {
    hasIncomes: trueOrUndefined(
      existingProperty && existingProperty.rentalAmount,
    ),
    hasLiabilities: trueOrUndefined(
      existingProperty && existingProperty.mortgageAmount,
    ),
    hasAssets: trueOrUndefined(existingProperty),
    lookingToBuyProperty: !!prospectiveProperty,
    ownOtherProperties: trueOrUndefined(existingProperty),
  };
}

export const createLoanApplication = (
  clientId,
  scenario,
  analyticsClientId,
) => {
  const { loanFeatures, loanType, name, properties } = scenario;
  const depositAmount = _.get(properties, 'prospective.depositAmount');
  const params = {
    source_id: LM_ONLINE_LOAN_APP_SOURCE_ID,
    client_id: clientId,
    features: arrayOfTruthyKeys(loanFeatures)
      .map((key) => LOAN_FEATURES_MAP[key])
      .concat(LOAN_TYPE_FEATURES_MAP[loanType]),
    purposes: buildPurposeFromScenario(scenario), // TODO: get purpose ids from the API
    scenario_name: name,
    deposit: depositAmount ? { amount: depositAmount } : undefined,
    metadata: JSON.stringify(buildMetadata(scenario)),
    analytics_client_id: analyticsClientId,
  };

  return postLoanApplicationForClientDirect(clientId, params);
};

function getPropertyStatusIdFromLmType(type) {
  const statusObj = PROPERTY_STATUSES.find((s) => s.name === type);

  return statusObj && statusObj.id;
}

export const createProperty = (clientId, loanApplicationId, property) => {
  const {
    locality,
    state,
    value,
    type,
    FHOGEligibility,
    ownerOccupied,
    isPreapproved,
  } = property;
  const [suburb, postcode, parsedState] = parseLocality(locality);
  const selectedState = parsedState || state;

  // Testing purpose
  if (!selectedState) {
    return Promise.resolve();
  }

  const params = {
    scenario_id: loanApplicationId,
    postcode,
    suburb: suburb || '', // Missing suburb causes API error
    state: selectedState,
    country: 'Australia',
    value,
    type,
    fhog_eligibility: FHOGEligibility === 'true' ? 'yes' : 'no',
    transaction_id: TRANSACTION_PURCHASING,
    status_id: getPropertyStatusIdFromLmType(type),
    is_preapproved: isPreapproved,
    client_ids: [clientId],
  };

  // TODO clean up ownerOccupied
  if (typeof ownerOccupied === 'string') {
    params.primary_purpose_id =
      ownerOccupied === OWNER_OCCUPIED_RESIDENCE
        ? PRIMARY_PURPOSE_OWNER_OCCUPIED.id
        : PRIMARY_PURPOSE_INVESTMENT.id;
  } else if (typeof ownerOccupied === 'boolean') {
    params.primary_purpose_id = ownerOccupied
      ? PRIMARY_PURPOSE_OWNER_OCCUPIED.id
      : PRIMARY_PURPOSE_INVESTMENT.id;
  }

  return postPropertyForApplicationDirect(loanApplicationId, params);
};

export const createIncomeForProperty = (propertyId, property) => {
  const { rentalAmount, rentalFrequency } = property;
  if (!rentalAmount) {
    return Promise.resolve();
  }

  const params = {
    property_id: propertyId,
    value: rentalAmount,
    frequency_id: MILLI_TO_MYCRM_FREQ_ID[rentalFrequency],
    category_id: INCOME_RENTAL_CATEGORY,
    type_id: INCOME_RENTAL,
    description: `From investment property to purchase in ${
      property.locality || property.state
    }`,
  };

  return postIncomeForPropertyDirect(propertyId, params);
};

function makeFundingParams(property, map) {
  let params = Object.keys(property)
    .filter((key) => Object.prototype.hasOwnProperty.call(map, key))
    .map((key) => ({
      value: map[key].value ? map[key].value(property) : property[key],
      name: map[key].name,
      type: map[key].type,
      percentage: 100,
      notes: property[map[key].notes],
      is_only_value_editable: true,
    }))
    .filter((param) => param.value);

  if (property.otherCosts && property.otherCosts.length) {
    params = params.concat(
      property.otherCosts.map((otherConst) => ({
        value: otherConst.value,
        name: otherConst.name,
        type: FUNDING_TYPE_REQUIRED,
        percentage: 100,
        is_only_value_editable: true,
      })),
    );
  }

  return params;
}

export const createFundingsForProperty = (propertyId, property, map) => {
  const params = makeFundingParams(property, map);
  return params.length
    ? postFundingsForPropertyDirect(propertyId, params)
    : Promise.resolve();
};

export const createRealEstate = (
  clientId,
  property,
  scenario,
  loanApplicationId,
) => {
  const {
    locality,
    rentalAmount,
    mortgageAmount,
    value,
    id,
    ownerOccupied,
  } = property;
  const params = {
    value,
    client_ids: [clientId],
    scenario_id: loanApplicationId,
    estate_address: locality,
    rental_income: scenario.name !== BUYER_SCENARIO_INVEST && !!rentalAmount,
    existing_mortgages: !!mortgageAmount,
    type_id: ASSET_TYPE_MAP[id],
    security_bond: scenario.name !== BUYER_SCENARIO_MOVE,
    ownership_percentage: 100,
    value_basis: 'Applicant Estimate', // value basis is defined as a const enum in mycrm.
    value_basis_id: 1,
  };

  if (typeof ownerOccupied === 'string') {
    params.primary_purpose_id =
      ownerOccupied === OWNER_OCCUPIED_RESIDENCE
        ? PRIMARY_PURPOSE_OWNER_OCCUPIED.id
        : PRIMARY_PURPOSE_INVESTMENT.id;
  } else if (typeof ownerOccupied === 'boolean') {
    params.primary_purpose_id = ownerOccupied
      ? PRIMARY_PURPOSE_OWNER_OCCUPIED.id
      : PRIMARY_PURPOSE_INVESTMENT.id;
  }

  return postRealEstateForClientDirect(clientId, params);
};

export const createIncomeForRealEstate = (
  clientId,
  familyId,
  assetId,
  property,
  applicationId,
  isRentalIncome,
) => {
  const { rentalAmount, rentalFrequency, locality } = property;
  if (!rentalAmount) {
    return Promise.resolve();
  }

  const params = {
    scenario_id: applicationId,
    asset_id: assetId,
    value: rentalAmount,
    frequency_id: MILLI_TO_MYCRM_FREQ_ID[rentalFrequency],
    client_id: clientId, // Client ID is required by API
    client_ids: [clientId],
    ownership_percentage: 100,
    category_id: INCOME_RENTAL_CATEGORY,
    type_id: INCOME_RENTAL,
    description: isRentalIncome
      ? `From property in ${locality}`
      : `Potential gross income from renting out existing home TBC`,
  };

  return isRentalIncome
    ? postRealEstateIncomeForAssetDirect(assetId, params)
    : postIncomeForContactDirect(familyId, params);
};

export const createLiabilityForRealEstate = (
  clientId,
  assetId,
  property,
  isRefinance,
  applicationId,
) => {
  const {
    mortgageAmount,
    currentLender,
    currentLenderId,
    currentInterestRate,
  } = property;
  if (!mortgageAmount) {
    return Promise.resolve();
  }

  const params = {
    scenario_id: applicationId,
    asset_id: assetId,
    client_id: clientId,
    creditor_id: currentLenderId,
    client_ids: [clientId],
    value: mortgageAmount,
    description: getLenderName(currentLenderId) || currentLender,
    interest_rate: currentInterestRate,
    type_id: LIABILITY_TERM_LOAN.id,
    category_id: LIABILITY_OTHER_CATEGORY.id,
    ownership_percentage: 100,
    is_refinance: isRefinance,
    mortgage_priority_id: 1,
  };

  return postRealEstateLiabilityForAssetDirect(assetId, params);
};

export const createExpenseForProperty = (clientId, property) => {
  return Object.keys(property)
    .filter((key) => !!EXPENSE_MAP[key])
    .map((key) =>
      postExpenseForClientDirect(clientId, {
        ...EXPENSE_MAP[key],
        value: property[key],
        client_ids: [clientId],
      }),
    );
};

export const createProperties = (
  clientId,
  familyId,
  loanApplicationId,
  scenario,
) => {
  const { properties } = scenario;

  if (!properties) {
    return Promise.resolve();
  }

  const safeProperties = properties.filter((key) => !!properties[key]);
  return Promise.all(
    Object.keys(safeProperties).map((key) => {
      if (key === PROSPECTIVE_PROPERTY_ID) {
        return Promise.all([
          createProperty(clientId, loanApplicationId, properties[key]).then(
            (property) => {
              if (property) {
                return Promise.all([
                  createFundingsForProperty(
                    property.property_id,
                    properties[key],
                    PROSPECTIVE_PROPERTY_FUNDING_MAP,
                  ),
                  createIncomeForProperty(
                    property.property_id,
                    properties[key],
                  ),
                ]);
              }
            },
          ),
          createExpenseForProperty(clientId, properties[key]),
        ]);
      }

      // BUYER_SCENARIO_MOVE, BUYER_SCENARIO_INVEST and REFINANCE_SCENARIO_DEFAULT
      return Promise.all([
        createRealEstate(
          clientId,
          properties[key],
          scenario,
          loanApplicationId,
        ).then((realEstate) => {
          const promises = [
            realEstate,
            createIncomeForRealEstate(
              clientId,
              familyId,
              realEstate.id,
              properties[key],
              loanApplicationId,
              scenario.name !== BUYER_SCENARIO_INVEST,
            ),
            createLiabilityForRealEstate(
              clientId,
              realEstate.id,
              properties[key],
              scenario.name === REFINANCE_SCENARIO_DEFAULT,
              loanApplicationId,
            ),
          ];

          if (scenario.name !== BUYER_SCENARIO_MOVE) {
            promises.push(
              createFundingsForProperty(
                realEstate.property_id,
                properties[key],
                OWNED_PROPERTY_FUNDING_MAP,
              ),
            );
          }

          return Promise.all(promises);
        }),
        createExpenseForProperty(clientId, properties[key]),
      ]);
    }),
  );
};

const defaultClient = {
  active: true,
  name: {
    display: '',
    first: '',
    last: '',
    middle: '',
  },
};

export const createCoApplicant = (
  clientId,
  familyId,
  loanApplicationId,
  serviceability,
) => {
  const { isJointApplication } = serviceability;
  const params = {
    ...defaultClient,
    co_applicant: isJointApplication,
    scenario_id: loanApplicationId,
    role_id: ROLE_ADULT,
    name: {
      ...defaultClient.name,
      first: 'Co-applicant',
    },
  };

  return postApplicantDirect(loanApplicationId, params);
};

export const createChild = (familyId, loanApplicationId, index) => {
  const params = {
    ...defaultClient,
    co_applicant: true,
    scenario_id: loanApplicationId,
    role_id: ROLE_CHILD,
    name: {
      ...defaultClient.name,
      first: `Child ${index}`,
    },
  };

  return postClientToFamilyDirect(familyId, params);
};

function propertyIsExistingHome(p) {
  return (
    p &&
    p.id !== PROSPECTIVE_PROPERTY_ID &&
    (p.ownerOccupied === true || p.ownerOccupied === OWNER_OCCUPIED_RESIDENCE)
  );
}

export const createAddress = (clientId, familyId, scenario) => {
  const { properties, locality, isPostcodeManualEntry } = scenario;
  const existingHome = _.find(properties, propertyIsExistingHome);
  const homeAddress =
    (existingHome && existingHome.locality) ||
    (isPostcodeManualEntry ? locality : null);
  if (!homeAddress) {
    return Promise.resolve();
  }

  const params = {
    client_ids: [clientId],
    formatted_address: homeAddress,
    address_type_id: ADDRESS_TYPE_CURRENT.id,
  };

  if (existingHome) {
    params.ownership_type_id = existingHome.mortgageAmount
      ? ADDRESS_OWNERSHIP_TYPE_OWN_HOME_MORTGAGE.id
      : ADDRESS_OWNERSHIP_TYPE_OWN_HOME.id;
  }
  return postAddressForContactDirect(familyId, params);
};

export const createScenario = (clientId, familyId, scenario, applicationId) =>
  createAddress(clientId, familyId, scenario)
    .then(() => {
      return createProperties(clientId, familyId, applicationId, scenario)
        .catch(notifyOrLog)
        .then(() => ({}));
    })
    .catch(notifyOrLog)
    .then(() => ({}));
