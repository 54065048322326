import { MILLI_TO_MYCRM_FREQ_ID } from 'shared/lib/frequencyHelper';
import {
  LIABILITY_CREDIT_CARD,
  LIABILITY_STORE_CARD,
} from 'shared/constants/myCRMTypes/liabilities';

import { historyBuilderForMyCRM } from './history';

export function liabilityBuilderForMyCRM(liability) {
  return {
    scenario_id: liability.applicationId,
    ownership_percentage: 100 / liability.clientIds.length,
    value: liability.totalLoanAmount,
    asset_id: liability.assetId,
    type_id: liability.type,
    category_id: liability.category,
    client_ids: liability.clientIds,
    description: liability.description,
    remaining_loan_term_years: liability.remainingYears,
    remaining_loan_term_months: liability.remainingMonths,
    repayments: liability.repaymentAmount,
    repayment_frequency_id:
      MILLI_TO_MYCRM_FREQ_ID[liability.repaymentFrequency],
    limit:
      liability.type === LIABILITY_CREDIT_CARD.id ||
      liability.type === LIABILITY_STORE_CARD.id
        ? liability.totalCardLimit
        : liability.originalLoanAmount,
    creditor_id: liability.creditorId,
    creditor_name: liability.creditorName,
    interest_tax_deductible: liability.isInterestTaxDeductible,
    is_refinance: liability.isRefinance,
    mortgage_priority_id: 1,
    ...historyBuilderForMyCRM(liability),
  };
}
