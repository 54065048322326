import {
  LONG_TERM,
  RETIREMENT,
  HORIZON,
  RETIREMENT_PATH,
  HORIZON_PATH,
  DASHBOARD_BASE_PATH,
  LONG_TERM_PATH,
  APPLY_BASE_PATH,
  LONG_TERM_GOALS_CONSOLIDATED,
} from 'shared/constants/paths';

import { isApplyOnline } from 'lib/pathHelper';
import { featureFlags } from 'lib/rollout';

export const getSection = ({
  section,
  family,
  client,
  nextClient = {},
  active,
}) => {
  const name = family ? family.familyFullName : client.displayName;
  const featureFlag =
    featureFlags['complianceQuestions.reduceDuplication'].isEnabled() ||
    featureFlags.consolidateFutureFinancialObjectives.isEnabled();

  const { contactId, id } = client;
  const pathPrefix = isApplyOnline()
    ? `${APPLY_BASE_PATH}/${active}`
    : `/${active}`;

  const nextPathByNextClient = nextClient.id
    ? `${pathPrefix}${RETIREMENT_PATH}/${contactId}/${nextClient.id}`
    : `${pathPrefix}${
        isApplyOnline() ? '' : DASHBOARD_BASE_PATH
      }?section=loanFeatures`;

  const nextPathLongTerm = featureFlag
    ? nextPathByNextClient
    : `${pathPrefix}${RETIREMENT_PATH}/${contactId}/${id}`;

  const nextPathHorizon = featureFlag
    ? `${pathPrefix}${LONG_TERM_PATH}/${contactId}/${id}`
    : nextPathByNextClient;

  switch (section) {
    case LONG_TERM:
    case LONG_TERM_GOALS_CONSOLIDATED:
      return {
        title: `What are ${name}’s long term goals?`,
        nextPath: nextPathLongTerm,
      };
    case RETIREMENT:
      return {
        title: `${name}'s retirement plans`,
        nextPath: `${pathPrefix}${HORIZON_PATH}/${contactId}/${id}`,
      };
    case HORIZON:
      return {
        title: `Are there any changes on the horizon for ${name}?`,
        nextPath: nextPathHorizon,
      };
    default:
      return {};
  }
};
