import { setLoggerSessionData } from 'lib/coreLogger';

import {
  SET_SHARED_CLIENT_ID,
  SET_SHARED_LOAN_APP_ID,
} from 'actions/scenarioActionTypes';
import { INITIALISE } from 'actions/UIActionTypes';

// eslint-disable-next-line unicorn/consistent-function-scoping
export default (store) => (next) => (action) => {
  switch (action.type) {
    case INITIALISE:
      setLoggerSessionData();
      break;
    case SET_SHARED_CLIENT_ID:
      setLoggerSessionData({ clientId: action.payload });
      break;
    case SET_SHARED_LOAN_APP_ID:
      setLoggerSessionData({ loanAppId: action.payload });
      break;
    default:
      break;
  }
  return next(action);
};
