import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVGInline from 'react-svg-inline';
import _ from 'lodash';

import { generateUniqueID } from 'lib/utils/stringUtils';

import AnimatedHeading from 'components/AnimatedHeading/AnimatedHeading';
import NotificationBanner from 'components/NotificationBanner/NotificationBanner';
import NotifBannerStyles from 'components/NotificationBanner/NotificationBanner.css';
import Form from 'components/Form/Form';
import styles from './ContentsWrapper.css';

class ContentsWrapper extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    headingIcon: PropTypes.node,
    className: PropTypes.string,
    extraHint: PropTypes.func,
    isForm: PropTypes.bool,
    formCompleted: PropTypes.bool,
    onSubmit: PropTypes.func,
    onFocusLost: PropTypes.func,
    subtitles: PropTypes.arrayOf(PropTypes.string),
    titleDescription: PropTypes.string,
    animateHeading: PropTypes.bool,
    showLightbox: PropTypes.bool,
    inverseHeader: PropTypes.bool,
    successMessage: PropTypes.string,
    warningMessage: PropTypes.string,
    isLocked: PropTypes.bool,
    centeredTitle: PropTypes.bool,
  };

  static defaultProps = {
    isForm: false,
    subtitles: [],
    animateHeading: true,
    inverseHeader: false,
  };

  renderTitle() {
    const { title, headingIcon, inverseHeader } = this.props;
    const titleStyles = classNames.bind(styles)({
      inverseTitle: inverseHeader,
    });

    if (_.isFunction(title)) {
      return title();
    }
    if (headingIcon) {
      return (
        <div>
          <SVGInline className={styles.headingIcon} svg={headingIcon} />
          <h1 className={titleStyles}>{title}</h1>
        </div>
      );
    }
    return <h1 className={titleStyles}>{title}</h1>;
  }

  renderHeading() {
    const { title, animateHeading, headingIcon, centeredTitle } = this.props;
    return (
      title &&
      (animateHeading && !headingIcon && !centeredTitle ? (
        <AnimatedHeading skipAllowed={false}>
          {this.renderTitle()}
        </AnimatedHeading>
      ) : (
        this.renderTitle()
      ))
    );
  }

  render() {
    const {
      title,
      isForm,
      children,
      formCompleted,
      extraHint,
      className,
      inverseHeader,
      id,
      subtitles,
      titleDescription,
      animateHeading,
      showLightbox,
      onSubmit,
      onFocusLost,
      headingIcon,
      successMessage,
      infoMessage,
      warningMessage,
      isLocked,
      centeredTitle,
    } = this.props;
    const rootStyles = classNames.bind(styles)('root', className);

    const titleWrapperStyle = classNames.bind(styles)('titleWrapper', {
      animatedTitleWrapper: animateHeading,
      hasHeadingIcon: !!headingIcon,
      inverseTitleWrapper: inverseHeader,
      hasWarning: !!warningMessage || !!successMessage,
      centeredTitle,
    });

    const lightboxClasses = classNames(styles.lightbox, {
      [styles.show]: showLightbox,
    });
    return (
      <section
        id={generateUniqueID('ContentsWrapper', id)}
        className={rootStyles}
      >
        {!!title && (
          <div className={titleWrapperStyle}>
            <div className={styles.container}>
              {this.renderHeading()}
              {subtitles.map((child, index) => (
                <h4 className={styles.subtitle} key={`subtitle-${index}`}>
                  {child}
                </h4>
              ))}
              {titleDescription && (
                <div className={styles.titleDescription}>
                  {titleDescription}
                </div>
              )}
            </div>
          </div>
        )}
        {!!successMessage && !isLocked && (
          <NotificationBanner
            message={successMessage}
            align='center'
            className={NotifBannerStyles.success}
          />
        )}
        {!!infoMessage && !isLocked && (
          <NotificationBanner
            message={infoMessage}
            align='center'
            className={NotifBannerStyles.info}
          />
        )}
        {!!warningMessage && !isLocked && (
          <NotificationBanner
            message={warningMessage}
            secondaryIcon='mi-icon-arrow-down'
            align='center'
          />
        )}
        <div className={lightboxClasses} />
        {isForm ? (
          <Form
            onSubmit={onSubmit}
            onFocusLost={onFocusLost}
            className={styles.container}
            formCompleted={formCompleted}
          >
            {children}
            <input
              className={styles.submit}
              disabled={!formCompleted}
              type='submit'
            />
          </Form>
        ) : (
          <div className={styles.container}>{children}</div>
        )}
        {extraHint && <div className={styles.extraHint}>{extraHint()}</div>}
      </section>
    );
  }
}

export default ContentsWrapper;
