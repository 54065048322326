import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxMirrorPropTypes } from '@loanmarket/react-redux-mirror';

import { percentageFormatter } from 'lib/utils/numberUtils';
import Questions from 'lib/Questions';
import { REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO } from 'constants/validators';

import Form from 'components/Form/Form';
import InputWithSpan from 'components/InputWithSpan/InputWithSpan';
import Button from 'components/Button/Button';
import Question from 'components/Question/Question';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

const questionSet = () => {
  const questions = new Questions();
  questions.addBranch(
    ['percent', REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO],
    ['value', REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO],
  );
  return questions.arrayOfQuestions();
};

class RealEstateAgentFees extends Component {
  static propTypes = {
    value: PropTypes.number,
    percent: PropTypes.number,
    baseValue: PropTypes.number,
    setValue: PropTypes.func.isRequired,
    setPercent: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
    ...reduxMirrorPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  handleUpdate = () => {
    const { sync, onSubmit } = this.props;
    sync();
    onSubmit();
  };

  handleOnKeyDown = (e) => {
    if (e.key === 'Enter' && this.formCompleted) {
      this.handleUpdate();
    }
  };

  relativePercentage() {
    const { value, baseValue } = this.props;
    return percentageFormatter(value / baseValue);
  }

  render() {
    const {
      value,
      setValue,
      percent,
      setPercent,
      questions,
      setCurrentQuestionTo,
      formCompleted,
    } = this.props;
    return (
      <Form
        formCompleted={formCompleted}
        onSubmit={this.handleUpdate}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <Question
          {...questions.percent}
          className='fullWidthInput'
          label='Percentage of sale price'
        >
          <InputWithSpan
            action={setPercent}
            spanValue='%'
            value={percent}
            onKeyDown={this.handleOnKeyDown}
            type='number'
          />
        </Question>
        <Question
          {...questions.value}
          className='fullWidthInput'
          label='Marketing and advertising fees'
        >
          <InputWithSpan
            inputComponent='CurrencyInput'
            action={setValue}
            spanValue={this.relativePercentage()}
            type='number'
            value={value}
            onKeyDown={this.handleOnKeyDown}
          />
        </Question>
        <Button
          disabled={!formCompleted}
          id='PopupButton'
          className='brandColor__button margin-0'
          onClick={this.handleUpdate}
        >
          Update
        </Button>
        <input className='hidden' type='submit' />
      </Form>
    );
  }
}

export default manageQuestions(RealEstateAgentFees);
