import _ from 'lodash';

export function serialiseLocality({ suburb, postcode, state }) {
  return `${suburb}, ${postcode ? `${postcode} ` : ''}${state}`;
}

const addressComponentMapper = {
  streetNumber: 'street_number',
  streetName: 'route',
  state: 'administrative_area_level_1',
  suburb: 'locality',
};

export const nullAddressComponents = {
  streetNumber: null,
  streetName: null,
  state: null,
  suburb: null,
};

export const mapPlaceDetailFromMyCRMPlaceDetail = (myCrmPlaceDetails) => {
  const placeDetails = {
    address_components: [],
  };
  _.each(myCrmPlaceDetails.AddressComponents, (value, key) => {
    const addressComponent = {
      long_name: value.LongName,
      short_name: value.ShortName,
      types: [],
    };
    _.each(value.Types, (type) => {
      addressComponent.types.push(type);
    });
    placeDetails.address_components.push(addressComponent);
  });
  return placeDetails;
};

export const mapAddressComponentsFromPlaceDetail = (
  details,
  streetTypes = [],
  // TODO: refactor mapAddressComponentsFromPlaceDetail to reduce complexity
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const { address_components: components } = details;
  if (components.length === 0) {
    return nullAddressComponents;
  }

  return components.reduce((map, a) => {
    const param = a.types && a.types.length > 0 ? a.types[0] : '';
    if (param === 'country') {
      map.country = a.long_name;
      map.countryCodeInTwoLetter = a.short_name;
    } else if (param === 'administrative_area_level_1') {
      map.administrative_area_level_1 = a.long_name;
      map.stateShort_Name = a.short_name;
    } else if (param === 'route') {
      map.route = a.long_name;
      const route = a.long_name.split(' ');
      if (
        route.length >= 2 &&
        !!streetTypes.find((s) => s?.Value === route[route.length - 1])
      ) {
        const streetType = route[route.length - 1];
        map.streetName = a.long_name.replace(` ${streetType}`, '');
        map.streetType = streetType;
      } else {
        map.streetName = a.long_name;
      }
    } else if (param === 'street_number') {
      const route =
        components.find((c) => c.types && c.types[0] === 'route') || {};
      map.street_address = `${a.long_name} ${route.long_name}`;
      map.street_number = a.long_name;
    } else {
      map[param] = a.short_name;
    }
    return map;
  }, {});
};

export const mapAddressObjectFromPlaceDetail = (detail) => {
  if (!detail) {
    return nullAddressComponents;
  }

  const address = { formattedAddress: detail.formatted_address };
  _.each(addressComponentMapper, (lookupField, mappedField) => {
    address[mappedField] = detail[lookupField];
  });
  return address;
};
