import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import { push } from '@loan_market/react-router-redux-multi';

import {
  REQUEST_PRIVACY_POLICY_INFO,
  EXECUTE_TOKEN,
} from 'actions/privacyPolicyActionTypes';
import UIActions from 'actions/UIActions';
import privacyPolicyActions from 'actions/privacyPolicyActions';

import { requestAdvisorInfo } from 'sagas/advisorSagas';
import { requestAdvisorOrgInfo } from 'sagas/advisorOrgSagas';

import * as privacyPolicySelectors from 'selectors/privacyPolicySelectors';

import LocalStorageProxy from 'lib/localStorageProxy';
import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import { fromPrivacyPolicyToNextPath } from 'lib/pathHelper';
import { logger as coreLogger } from 'lib/coreLogger';

import {
  HELP_INVALID_PRIVACY_POLICY_PATH,
  HELP_ALREADY_EXECUTED_PRIVACY_POLICY_PATH,
  NEXT_STEPS_PATH,
} from 'shared/constants/paths';
import { getTokenStatus, executeToken } from 'services/actionTokenApi';

export function* requestPrivacyPolicyInfo({
  payload: { token, name, advisorUuid },
}) {
  try {
    yield put(UIActions.startFullPageSpinner());
    const advisor = yield call(requestAdvisorInfo, {
      payload: {
        uuid: advisorUuid,
        showOffice: true,
        validateMlgCapability: false,
      },
    });
    const { advisorOrgSlug, advisorOrgId } = advisor;
    if (advisorOrgSlug) {
      LocalStorageProxy.advisorOrgSlug = advisorOrgSlug;
      yield call(requestAdvisorOrgInfo, {
        payload: {
          id: advisorOrgId,
          slug: advisorOrgSlug,
          shouldFetchPrincipleAdvisor: false,
          shouldContinueSpinner: true,
        },
      });
    }
    const { isValidToken, isAlreadyExecuted } = yield call(
      getTokenStatus,
      token,
    );

    if (!isValidToken) {
      const errorPath = isAlreadyExecuted
        ? HELP_ALREADY_EXECUTED_PRIVACY_POLICY_PATH
        : HELP_INVALID_PRIVACY_POLICY_PATH;
      yield put(UIActions.stopFullPageSpinner());
      yield put(push(errorPath));
      return;
    }

    yield put(privacyPolicyActions.setToken(token));
    yield put(privacyPolicyActions.setIsTokenValid(true));
    yield put(privacyPolicyActions.setClientName(name));
    yield put(UIActions.stopFullPageSpinner());
    return advisor;
  } catch (error) {
    yield put(UIActions.stopFullPageSpinner());
    yield put(UIActions.setPageError({ status: 404, error }));
  }
}

export function* executePrivacyPolicyToken() {
  const logger = coreLogger('userSagas');
  try {
    yield put(privacyPolicyActions.executingToken(true));
    const token = yield select(privacyPolicySelectors.token);
    const { success } = yield call(executeToken, token);

    yield put(UIActions.stopFullPageSpinner());
    if (!success) {
      yield put(push(HELP_INVALID_PRIVACY_POLICY_PATH));
    } else {
      yield put(
        UIActions.goToPathWithAnimation(
          fromPrivacyPolicyToNextPath(NEXT_STEPS_PATH),
        ),
      );
    }
    yield put(privacyPolicyActions.executingToken(false));
  } catch (error) {
    yield put(privacyPolicyActions.executingToken(false));
    logger.error({
      action: 'executePrivacyPolicyToken',
      errorDescription: 'Failed to execute privacy policy token',
      errorStack: error,
    });
    yield put(UIActions.setPageError({ status: 404, error }));
  }
}

export default function* privacyPolicySagas() {
  yield all([
    yield monitorAsyncRequest(
      takeLatest,
      REQUEST_PRIVACY_POLICY_INFO,
      requestPrivacyPolicyInfo,
    ),
    yield monitorAsyncRequest(
      takeLatest,
      EXECUTE_TOKEN,
      executePrivacyPolicyToken,
    ),
  ]);
}
