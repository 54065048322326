import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import EditIcon from 'assets/icons/edit-icon.svg';

import * as expenseSelectors from 'selectors/expenseSelectors';
import * as liabilitySelectors from 'selectors/liabilitySelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';

import expenseActions from 'actions/expenseActions';

import UIActions from 'actions/UIActions';

import { SERVICEABILITY_CHART_SPECTRUM } from 'constants/colours';

import ReportContent from 'components/ReportContent/ReportContent';
import applySection from 'hocs/applySection';
import { logEvent, EVENTS } from 'lib/amplitude';

import { EXPENSES_SLUG } from 'constants/applyData';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import NotifBannerStyles from '../NotificationBanner/NotificationBanner.css';
import SVGInline from 'react-svg-inline';

const messages = defineMessages({
  title: {
    id: 'ExpensesApply.title',
    defaultMessage: 'Expenses',
  },
  titleDescription: {
    id: 'ExpensesApply.titleDescription',
    defaultMessage: 'Tell us about your expenses',
  },
  headerDescription: {
    id: 'ExpensesApply.headerDescription',
    defaultMessage: 'Let’s take a look at your expenses.',
  },
  titleDescriptionCompleted: {
    id: 'ExpensesApply.titleDescriptionCompleted',
    defaultMessage: '-{totalMonthlyExpenses, number, currency} p.m.',
  },
  headerDescriptionCompleted: {
    id: 'ExpensesApply.headerDescriptionCompleted',
    defaultMessage:
      'Your expenses are {totalMonthlyExpenses, number, currency} per month.',
  },
  addExpense: {
    id: 'ExpensesApply.addExpense',
    defaultMessage: 'Add an expense',
  },
  addAnotherExpense: {
    id: 'ExpensesApply.addAnotherExpense',
    defaultMessage: 'Add another expense',
  },
});

export const furtherDecoration = (props) => {
  const {
    totalMonthlyExpenses,
    anyExpensePending,
    intl: { formatMessage },
    warningMessage,
  } = props;
  const postfix = totalMonthlyExpenses && !anyExpensePending ? 'Completed' : '';

  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      totalMonthlyExpenses,
    }),
    headerDescription: anyExpensePending
      ? 'Let’s confirm your expenses look right.'
      : formatMessage(messages[`headerDescription${postfix}`], {
          totalMonthlyExpenses,
        }),
    warningMessage,
  };
};

const confirmEntities = (props) => {
  props.confirmExpenses({
    id: props.applicationId,
    expenses: props.primaryApplicantExpenses,
  });
};

class ExpensesApply extends Component {
  static displayName = 'ExpensesApply';
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    intl: intlShape.isRequired,
    totalMonthlyExpenses: PropTypes.number.isRequired,
    reportMetadata: PropTypes.arrayOf(PropTypes.object),
    liabilityExpenseMetadata: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    anyExpensePending: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    warningMessage: PropTypes.string,
    accordionProps: PropTypes.object,
    setMetadata: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    clientIdOwnershipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    applicationId: PropTypes.number,
  };

  componentDidUpdate(prevProps) {
    const { isOpen, setMetadata } = this.props;
    if (!prevProps.isOpen && isOpen) {
      setMetadata({ hasExpandedExpenses: true });
    }
  }

  buildReportData() {
    const {
      reportMetadata,
      totalMonthlyExpenses,
      liabilityExpenseMetadata,
    } = this.props;

    // TODO: fetch mimimum expenses from serviceability call - watch for income/household shape changes
    const minimumExpenses = 0;
    const remainder = minimumExpenses - (totalMonthlyExpenses || 0);

    const totalSection = {
      label: 'Expenses',
      value:
        totalMonthlyExpenses > minimumExpenses
          ? totalMonthlyExpenses
          : minimumExpenses,
      isChartTotal: true,
    };

    const hiddenDifference = {
      label: 'Assumed expenses',
      value: remainder,
      hidden: true,
    };

    const data = [
      totalSection,
      hiddenDifference,
      ...reportMetadata,
      liabilityExpenseMetadata,
    ];
    data.forEach((v, i) => {
      v.isOpenByDefault = v.isOpenByDefault || i <= 2;
    });

    return data;
  }

  onAddExpense = () => {
    logEvent(EVENTS.ADD_FINANCIALS, { section: EXPENSES_SLUG });
  };

  render() {
    const {
      accordionProps: { isLocked },
      isDataCollectionBankStatementsSuccessful,
      displayDataCollectionBanners,
    } = this.props;

    return (
      <div id='expensesApply'>
        <div>
          {displayDataCollectionBanners &&
            isDataCollectionBankStatementsSuccessful && (
              <NotificationBanner
                message='Expenses ready for review! Using your bank statement data, we have successfully added some expenses. Please review and add expenses to reflect your post settlement situation.'
                align='center'
                className={NotifBannerStyles.success}
              />
            )}
          {!displayDataCollectionBanners && (
            <>
              <p>
                To help create a living budget we’ve come up with a set of
                common expense items that you can play with. It’s important to
                be sure you can afford a loan and cover your basic living costs.
              </p>
              <p>
                Examples include: Food & Drinks, Gym & Sports, School Fees,
                Travel & Holidays etc{' '}
              </p>
            </>
          )}
        </div>
        <ReportContent
          data={this.buildReportData()}
          frequency={12}
          spectrum={SERVICEABILITY_CHART_SPECTRUM}
          isLocked={isLocked}
          onSectionClick={this.onAddExpense}
          simpleDonutSection
          rightIconSvg={<SVGInline svg={EditIcon} width={20} height={20} />}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalMonthlyExpenses: expenseSelectors.totalMonthlyExpenses(state),
  reportMetadata: expenseSelectors.reportMetadata(state),
  anyExpensePending: expenseSelectors.anyExpensePending(state),
  liabilityExpenseMetadata: liabilitySelectors.liabilityExpenseMetadata(state),
  isCustomerCare: applicationSelectors.isBrokerCustomerCare(state),
  primaryApplicantExpenses: expenseSelectors.primaryContactExpenses(state),
  clientIdOwnershipOptions: clientSelectors.clientIdOwnershipOptions(state),
  applicationId: applicationSelectors.getApplicationId(state),
  isDataCollectionBankStatementsSuccessful: dataCollectionSelectors.isBankStatementsSuccessful(
    state,
  ),
  displayDataCollectionBanners: expenseSelectors.displayDataCollectionBanners(
    state,
  ),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startAnimationSequence: UIActions.startAnimationSequence,
      confirmExpenses: expenseActions.confirmExpenses,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    applySection({
      iconName: 'sl-custom-atm',
      furtherDecoration,
      confirmEntities,
    })(ExpensesApply),
  ),
);
